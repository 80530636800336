

























































































































import { Component, Vue, Prop } from "vue-property-decorator"
import OcularIcon from "~/components/OcularIcon.vue"
import Offer from "~/models/Offer"
import DealDocumentsColl from "~/collections/DealDocuments"
import DocumentItem from "./DocumentItem.vue"
import { getEnumValues, state } from "@/store"
import DealDocument from "~/models/DealDocument"
import snackbar from "~/plugins/snackbar"

@Component({
  components: {
    OcularIcon,
    DocumentItem,
  },
})
export default class DocumentsManager extends Vue {
  @Prop({ required: true }) offer!: Offer
  selectedIds = this.offer.deal_document_ids
  dealDocuments = new DealDocumentsColl()
  documentView = "main"
  importedDocument: DealDocument | null = null
  loader = false
  isFileImport = false
  isRefresh = false

  /**
   * HOOKS
   */
  mounted() {
    this.fetchDealDocuments()
  }

  /**
   * PROPS
   */
  get documentNatures() {
    // Prevent user to set nature "signed_offer"
    const entries = Object.entries(getEnumValues("document_nature")).filter(
      (value) =>
        value[0] !== "signed_offer" && value[0] !== "countersigned_offer"
    )
    return entries.map(([value, text]) => ({
      value,
      text,
    }))
  }

  get selectedItems() {
    return this.selectedIds
      .map((id) => {
        return this.dealDocuments.items.find((document: DealDocument) => {
          return document.id === id
        })
      })
      .filter((document) => document !== null)
  }

  get notSelectedItems() {
    return this.dealDocuments.items.filter((document) => {
      return !(document.id && this.selectedIds.includes(document.id))
    })
  }

  /**
   * METHODS
   */

  async fetchDealDocuments() {
    this.dealDocuments.deal_id = state.currentDeal.id
    this.loader = true
    await this.dealDocuments.fetch()
    this.stopLoading()
  }

  onAddDocumentClickBtn(document: DealDocument) {
    if (document.nature === "unknown") {
      this.importedDocument = document
      this.documentView = "documentNature"
    } else {
      this.addDocument(document)
      this.documentView = "main"
    }
  }

  onSelectionChange(selectedIds: number[]) {
    this.selectedIds = selectedIds

    this.$emit("change", this.selectedIds)
  }

  async onNatureChange() {
    if (this.importedDocument) {
      if (this.importedDocument.nature !== "unknown") {
        await this.importedDocument.save()
        this.documentView = "main"
        this.addDocument(this.importedDocument)
        this.importedDocument = null
        await this.fetchDealDocuments()
      }
    }
  }

  addDocument(document: DealDocument) {
    const newSelection = this.selectedIds
    if (document.id) {
      newSelection.push(document.id)
    }
  }

  async refreshList() {
    if (!this.loader) {
      this.isRefresh = true
      this.loader = true
      await this.dealDocuments.refresh()
      this.stopLoading()
    }
  }

  openFileImporter() {
    const input = this.$refs.fileImporter as HTMLInputElement
    input.click()
  }

  async onFileChanged(event: Event) {
    this.loader = true
    this.isFileImport = true
    const input = event.target as HTMLInputElement
    if (input.files?.length) {
      const file = input.files[0]
      if (!file.type.match("application/pdf")) {
        snackbar({
          content: "Seul les documents PDF sont acceptés",
          color: "warning",
        })
      } else if (this.offer?.deal?.id) {
        const document = new DealDocument()
        document.deal_id = this.offer.deal.id || 0
        this.importedDocument = await document.import(file, "unknown")
        this.documentView = "documentNature"
      }
    }
    this.fetchDealDocuments()
  }

  stopLoading() {
    this.loader = false
    this.isFileImport = false
    this.isRefresh = false
  }
}
