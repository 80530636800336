














import { Component, Vue } from "vue-property-decorator"
import OcularPrompt from "~/components/OcularPrompt.vue"

@Component({
  components: {
    OcularPrompt,
  },
})
export default class PromptOverwriteOffer extends Vue {
  open() {
    // @ts-ignore
    this.$refs.dialog.open = true
  }

  async confirm() {
    this.$emit("confirm")
  }
}
