




















import { Vue, Component, Prop } from "vue-property-decorator"
import OcularDialog from "./OcularDialog.vue"

const defaultTitle = "Êtes-vous sûr ?"
const defaultMessage = "Cette action est irréversible. Êtes-vous certains ?"

@Component({
  components: {
    OcularDialog,
  },
})
export default class OcularConfirm extends Vue {
  @Prop({ required: false, default: defaultTitle }) title!: string
  @Prop({ required: false, default: defaultMessage }) message!: string
  @Prop({ required: true }) callback!: () => void
  loading = false

  /**
   * Computed props
   */
  get dialog(): OcularDialog {
    return this.$refs.dialog as OcularDialog
  }

  /**
   * Methods
   */
  open() {
    this.dialog.open = true
  }

  async onConfirm() {
    this.loading = true
    await this.callback()
    this.loading = false
    this.dialog.close()
  }

  onCancel() {
    this.dialog.close()
  }
}
