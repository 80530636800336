






















import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import Deal from "~/models/Deal"
import Profile from "~/models/Profile"
import DealCustomer from "./DealCustomer.vue"

@Component({
  components: {
    OcularAvatar,
    DealCustomer,
  },
})
export default class DealSignatories extends Vue {
  @Prop({ required: true }) deal!: Deal

  removeFromSignatories(signatory: Profile) {
    this.deal.signatories = this.deal.signatories.filter(
      (currSignatory) => currSignatory.id !== signatory.id
    )
    this.deal.signatory_ids =
      this.deal.signatories?.map((signatory) => signatory.id) || []
  }
}
