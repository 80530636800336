import { render, staticRenderFns } from "./OfferFormModale.vue?vue&type=template&id=10f4201c&scoped=true&"
import script from "./OfferFormModale.vue?vue&type=script&lang=ts&"
export * from "./OfferFormModale.vue?vue&type=script&lang=ts&"
import style0 from "./OfferFormModale.vue?vue&type=style&index=0&id=10f4201c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10f4201c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VCard,VCardActions,VCardText,VCol,VRadioGroup,VRow,VSwitch,VTextField,VTextarea,VTooltip})
