















































import { translate } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import OcularConfirm from "~/components/OcularConfirm.vue"
import DealDocument, { DealDocumentNature } from "~/models/DealDocument"

@Component({
  components: {
    OcularConfirm,
  },
})
export default class DocumentItem extends Vue {
  @Prop({ required: true }) document!: DealDocument
  @Prop({ required: false }) selection!: number[]

  get documentIsSelected() {
    return this.selection.findIndex((id) => id === this.document.id) !== -1
  }

  /**
   * Methods
   */
  removeDocumentFromSelection() {
    this.$emit(
      "change",
      this.selection.filter((id) => id !== this.document.id)
    )
  }

  addDocumentFromSelection() {
    this.$emit("add", this.document)
  }

  humanizeDocumentNature(nature: DealDocumentNature) {
    return translate("document_nature", nature)
  }
}
