import axios, { AxiosRequestConfig } from "axios"
import DealDocument from "~/models/DealDocument"
import snackbar from "~/plugins/snackbar"
import Collection from "../jrdm-mc/Collection"

export default class DealDocuments extends Collection<DealDocument> {
  model() {
    return DealDocument
  }
  static route = "deals/{deal_id}/documents"
  static responseField = "deal_documents"
  static refreshRoute = "deals/{deal_id}/documents/refresh"

  deal_id: number | null = null

  async refresh() {
    this._loaded = false
    const options: AxiosRequestConfig = {}
    options.method ||= "POST"
    options.url ||= this.renderRoute(DealDocuments.refreshRoute)
    try {
      const response = await axios(options)
      this.assignReceivedData(response.data)
      this._loaded = true
      this.dispatchEvent("loaded")
      return response.data
    } catch (error) {
      snackbar({ content: "Une erreur est survenue, contactez les devs" })
    }
  }
}
