



















import { Component, Vue, Prop } from "vue-property-decorator"
import OcularAvatar from "~/components/OcularAvatar.vue"
import Profile from "~/models/Profile"

@Component({
  components: {
    OcularAvatar,
  },
})
export default class DealCustomer extends Vue {
  @Prop({ required: true }) customer!: Profile
  @Prop({ type: Boolean, default: false }) removable!: boolean

  remove() {
    this.$emit("remove", this.customer)
  }
}
