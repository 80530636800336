







































































































































import DealCustomer from "@/components/deals/DealCustomer.vue"
import { translate } from "@/store"
import { Component, Vue, Prop } from "vue-property-decorator"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import DealDocument from "~/models/DealDocument"
import Offer from "~/models/Offer"

@Component({
  components: {
    AgentItem,
    DealCustomer,
  },
})
export default class OfferSummary extends Vue {
  @Prop({ required: true }) offer!: Offer
  @Prop({ required: false, default: null }) currentStep!: number | null

  documentNature(document: DealDocument) {
    if (document.nature) return translate("document_nature", document.nature)
    return "Inconnu"
  }

  get signatories() {
    return this.offer.deal?.signatories || []
  }

  get signatoriesFullAddress() {
    if (this.offer.buyer_street)
      return `${this.offer.buyer_street}, ${this.offer.buyer_zipcode} ${this.offer.buyer_city}`
    return null
  }
}
