






























import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Deal from "~/models/Deal"
import Profile from "~/models/Profile"

@Component({
  components: {},
})
export default class SignatoriesEditor extends Vue {
  @Prop({ required: true }) deal!: Deal
  signatory: Profile | null = null

  editorOpened = false

  /**
   * Getters
   */
  get notSelectedCustomers() {
    return this.deal?.customers?.filter((customer) => {
      const idxFound = this.deal.signatories.findIndex(
        (signatory) => signatory.id === customer.id
      )
      return idxFound === -1
    })
  }

  /**
   * Hooks
   */
  @Watch("signatory")
  addSelectedSignatory() {
    if (this.signatory) {
      this.deal.signatories.push(this.signatory)
      this.signatory = null
      this.editorOpened = false
    }
    this.deal.signatory_ids =
      this.deal.signatories?.map((signatory) => signatory.id) || []
  }
}
