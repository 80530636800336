



















































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import Offer from "~/models/Offer"
import OcularDialog from "~/components/OcularDialog.vue"
import snackbar from "~/plugins/snackbar"
import Agent from "~/models/Agent"
import OcularIcon from "~/components/OcularIcon.vue"
import Agencies from "../../modals/agencyModal/Agencies.vue"
import DocumentsManager from "../DocumentManager/DocumentsManager.vue"
import Agency from "~/models/Agency"
import Agents from "../../modals/agencyModal/Agents.vue"
import OfferSummary from "./OfferSummary.vue"
import { state } from "@/store"
import House, { PropertyType } from "~/models/House"
import AgencyPickerItem from "@/components/agencies/AgencyPickerItem.vue"
import AgentItem from "~/components/Inputs/AgentSelect/AgentItem.vue"
import PromptOverwriteOffer from "../../modals/PromptOverwriteOffer.vue"
import GoogleAddressAutocomplete, {
  Address,
} from "~/components/Inputs/GoogleAddressAutocomplete.vue"
import DealSignatories from "@/components/deals/DealSignatories.vue"
import SignatoriesEditor from "./SignatoriesEditor.vue"

@Component({
  components: {
    OcularDialog,
    OcularIcon,
    Agencies,
    Agents,
    DocumentsManager,
    OfferSummary,
    AgencyPickerItem,
    AgentItem,
    PromptOverwriteOffer,
    GoogleAddressAutocomplete,
    DealSignatories,
    SignatoriesEditor,
  },
})
export default class OfferFormModale extends Vue {
  @Prop({ required: false }) offer!: Offer
  @Prop({ default: null }) onValidate!: null | ((offer: Offer) => any)
  @Prop({ required: true }) house!: House
  form = new Offer()
  loading = false
  currentStep = 0
  offerIncludesSuspensiveConditions = this.form.contribution_amount || false
  editDate: string | null = null
  endValidityDate: string | null = null
  endValidityTime: string | null = null
  signatoriesEditorOpened = false

  mounted() {}

  /**
   * Hooks
   */
  @Watch("offer")
  initForm() {
    const now = new Date()
    const nowDate = now.toISOString().split("T")[0]

    if (!this.offer || this.offer.id === null) {
      this.form.bindDefaultValues(state.currentDeal, this.house)
    } else {
      this.form = this.offer
      this.form.deal = state.currentDeal
      if (this.offer.created_at)
        this.editDate = this.offer.created_at?.toISOString().split("T")[0]
    }
    this.editDate = nowDate
    if (this.form.end_validity_at) {
      // Format a YYYY/MM/DD format from end_validity_at __WITH CURRENT TIME__
      const year = this.form.end_validity_at
        .getFullYear()
        .toString()
        .padStart(4, "0")
      const month = (this.form.end_validity_at.getMonth() + 1)
        .toString()
        .padStart(2, "0")
      const day = this.form.end_validity_at
        .getDate()
        .toString()
        .padStart(2, "0")
      this.endValidityDate = `${year}-${month}-${day}`

      // Format a HH:mm format from end_validity_at __WITH CURRENT TIME__
      const hours = this.form.end_validity_at
        .getHours()
        .toString()
        .padStart(2, "0")
      const minutes = this.form.end_validity_at
        .getMinutes()
        .toString()
        .padStart(2, "0")
      this.endValidityTime = `${hours}:${minutes}`
    }
    this.form.deal = state.currentDeal
    this.form.house = this.house
    this.form.house_id = this.house.id
  }

  @Watch("editDate")
  onEditDateChange() {
    this.form.created_at = this.editDate ? new Date(this.editDate) : null
  }

  @Watch("offer.created_at")
  onOfferCreatedAtChange() {
    this.editDate = this.form.created_at?.toISOString().split("T")[0] || null
  }

  @Watch("endValidityDate")
  @Watch("endValidityTime")
  onEndValidityDateOrTimeChange() {
    if (this.endValidityDate && this.endValidityTime) {
      const day = parseInt(this.endValidityDate.split("-")[2])
      const mounth = parseInt(this.endValidityDate.split("-")[1])
      const year = parseInt(this.endValidityDate.split("-")[0])
      const hours = parseInt(this.endValidityTime.split(":")[0])
      const minutes = parseInt(this.endValidityTime.split(":")[1])
      this.form.end_validity_at = new Date()
      this.form.end_validity_at?.setDate(day)
      this.form.end_validity_at?.setMonth(mounth - 1)
      this.form.end_validity_at?.setFullYear(year)
      this.form.end_validity_at?.setHours(hours)
      this.form.end_validity_at?.setMinutes(minutes)
      this.form.end_validity_at?.setSeconds(0)
      this.form.end_validity_at?.setMilliseconds(0)
    } else {
      this.form.end_validity_at = null
    }
  }

  @Watch("offerIncludesSuspensiveConditions")
  onOfferIncludesSuspensiveConditionsChange() {
    if (!this.offerIncludesSuspensiveConditions) {
      this.form.contribution_amount = null
    }
  }

  @Watch("form.house")
  @Watch("form.house.price")
  @Watch("form.house.address")
  generateHouseDesignation() {
    const isF = this.form.house.property_type === PropertyType.house
    const components = []
    // Property type
    if (this.form.house.property_type === PropertyType.flat) {
      components.push("Un appartement")
    } else if (this.form.house.property_type === PropertyType.house) {
      components.push("Une maison")
    } else {
      components.push("Un bien")
    }

    // Living area
    if (this.form.house.living_area)
      components.push(`de ${this.form.house.living_area}m²,`)

    // Floor
    if (this.form.house.floor !== null) {
      components.push(`situé${isF ? "e" : ""}`)
      if (this.form.house.floor === 0) components.push(`au rez-de-chaussé,`)
      else if (this.form.house.floor === 1) components.push(`au premier étage,`)
      else if (this.form.house.floor)
        components.push(`au ${this.form.house.floor}ème étage,`)
    }

    // Address
    if (this.form.house.floor && this.form.house.address) components.push("du")
    else components.push(`situé${isF ? "e" : ""}`)
    if (this.form.house.address) {
      components.push(this.form.house.address + ",")
    }

    // Price
    if (this.form.house.price) {
      const formattedPrice = Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
      }).format(this.form.house.price)
      components.push(`affiché${isF ? "e" : ""} au prix de ${formattedPrice}`)
    }

    // Room count
    if (this.form.house.room_count) {
      components.push(
        `et composé de ${this.form.house.room_count} pièce${
          this.form.house.room_count > 1 ? "s" : ""
        }`
      )
    }

    if (this.form.house.room_count && this.form.house.bedroom_count)
      components.push("dont")
    else if (this.form.house.bedroom_count) components.push("possédant")

    // Room count
    if (this.form.house.bedroom_count) {
      components.push(
        `${this.form.house.bedroom_count} chambre${
          this.form.house.bedroom_count > 1 ? "s" : ""
        },`
      )
    }

    if (this.form.house.cellar === true) {
      components.push(`Une cave est également jointe au bien.`)
    }

    // Generate house_designation
    this.form.house_designation = components.join(" ")
  }

  /**
   * Getters
   */
  get steps() {
    return [
      "Les signataires",
      "Le vendeur",
      "Le vendeur",
      "Le bien",
      "L'offre",
      "Les documents",
    ]
  }

  get formattedHousePrice() {
    if (this.house.price)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        this.house.price
      )
    return ""
  }

  get formattedAmount() {
    if (this.form.amount)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        this.form.amount
      )
    return ""
  }

  set formattedAmount(value) {
    this.form.amount = parseInt(value.replace(/\s+|[a-z]+/g, ""), 10)
  }

  get formattedContributionAmount() {
    if (this.form.contribution_amount)
      return new Intl.NumberFormat("FR-fr", { style: "decimal" }).format(
        this.form.contribution_amount
      )
    return ""
  }

  set formattedContributionAmount(value) {
    this.form.contribution_amount = parseInt(
      value.replace(/\s+|[a-z]+/g, ""),
      10
    )
  }

  get textOk() {
    if (this.currentStep < this.steps.length - 2) return "Suivant"
    if (this.currentStep == this.steps.length - 2) {
      return this.form.id ? `Mettre à jour l'offre` : `Créer l'offre`
    }
    return "J'ai terminé"
  }

  get textCancel() {
    if (this.currentStep === this.steps.length - 1) {
      return null
    }
    return this.currentStep <= 0 ? "Annuler" : "Retour"
  }

  get dialog() {
    return this.$refs.dialog as OcularDialog
  }
  get buyerFullAddress() {
    if (this.form.deal?.buyer_street)
      return `${this.form.deal.buyer_street}, ${this.form.deal.buyer_zipcode} ${this.form.deal.buyer_city}`
    return null
  }
  get houseFullAddress() {
    return this.house.address
  }

  /**
   * METHODS
   */
  open() {
    this.initForm()
    this.dialog.open = true
  }

  close() {
    this.dialog.open = false
    this.currentStep = 0
  }

  async next() {
    if (this.currentStep < this.steps.length - 2) {
      this.currentStep++
    } else if (this.currentStep === this.steps.length - 2) {
      if (this.form.doc_drive_id) {
        // If a google drive doc already exist, open prompt modal to confirm overwritting
        const modale = this.$refs.promptOverwriteOffer as PromptOverwriteOffer
        modale.open()
      } else {
        // Direct save
        this.saveOffer()
      }
    } else {
      await this.saveDocuments()
      this.currentStep = 0
    }
  }

  async saveOffer() {
    this.loading = true

    // Request doc drive to be regenerated on edit
    this.form.doc_drive_id = null
    this.form.status = "draft"

    await this.form.deal?.save()
    await this.form.saveAndAssign()
    if (this.onValidate) this.onValidate(this.offer)
    else {
      snackbar({
        color: "success",
        content: `L'offre a correctement été ${
          this.form.id ? "mise à jour" : "créée"
        } 👌`,
      })
      this.$emit("updated", this.form)
    }
    this.loading = false
    this.currentStep++
  }

  async saveDocuments() {
    await this.form.save()
    if (this.onValidate) this.onValidate(this.offer)
    else if (this.form.deal_document_ids.length > 0) {
      snackbar({
        color: "success",
        content: `Les documents ont correctement été ajoutés 👌`,
      })
      this.$emit("updated", this.form)
    }
    this.dialog.close()
  }

  offerDocumentsChanged(selection: number[]) {
    this.form.deal_document_ids = selection
    this.$emit("updated", this.form)
  }

  updateAgency(agency: Agency | null) {
    this.form.agency = agency
    if (agency) {
      this.form.seller_type = agency.individual ? "individual" : "agency"
      this.form.agency = agency
      this.form.agency_id = agency.id
    } else {
      this.form.seller_type = null
      this.resetAgency()
    }
  }

  updateAgent(agent: Agent | null) {
    this.form.agent = agent
    this.form.agent_id = agent?.id || null
    this.form.seller_email = agent?.email || null
    this.form.seller_phone_number = null
  }

  canceled() {
    if (this.currentStep > 0) this.currentStep--
    else this.close()
  }

  resetAgency() {
    this.form.agency = null
    this.form.agency_id = null
    this.resetSeller()
  }

  setSellerAsIndividual() {
    this.resetAgency()
    this.form.seller_type = "individual"
    this.currentStep++
  }

  resetSeller() {
    this.updateAgent(null)
  }

  updateSellerType(sellerType: "agency" | "individual" | null) {
    this.resetAgency()
    this.form.seller_type = sellerType
  }

  buyerAddressChanged(location: Address | null) {
    this.form.buyer_street = location?.address || null
    this.form.buyer_zipcode = location?.zipcode || null
    this.form.buyer_city = location?.cityName || null
  }

  houseAddressChanged(location: Address | null) {
    this.form.house.address = location?.fullFormattedAddress || null
    this.house.latitude = location?.latitude || null
    this.house.longitude = location?.longitude || null
    let data = {
      address: this.house.address,
      latitude: this.house.latitude,
      longitude: this.house.longitude,
    }
    if (this.house.id) {
      this.house.save({
        method: "PUT",
        url: `houses/${this.house.id}`,
        data: { house: data },
      })
    }
  }

  // Form rules
  notEmpty = (value: string | Array<any> | null) => {
    return !!(value && value.length) || "Champ requis"
  }

  notEmptyNumber = (value: number | Array<any> | null) => {
    return !!(value && value != 0) || "Champ requis"
  }
}
